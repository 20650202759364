import React from 'react';
import s from '../styles/about.module.css';

export default ({ children, secondary }) => (
  <section className={`${s.aboutSection} ${secondary ? s.secondary : ''}`}>
    <div className="container">
      <div className="row">
        <div className={`col-md-10 mx-auto ${s.description}`}>{children}</div>
      </div>
    </div>
  </section>
);
